// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-data-js": () => import("../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-events-js": () => import("../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-js": () => import("../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-publications-js": () => import("../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-sponsors-js": () => import("../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */)
}

